body {
  font-family: 'Lato', sans-serif;
  background: linear-gradient(#ffffff, #ccd0e4);
}

.App {
  text-align: center;
}

.App-logo {
  width: 280px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

@media only screen and (max-width: 768px) {
  .App-logo {
    width: 230px;
  }
}

@media only screen and (max-width: 600px) {
  .App-logo {
    width: 180px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
